<template>
	<div id="tu-course-details">
		<el-drawer v-model="dialogVisible" :direction="direction" size="50%">
			<template #title>
				<h4>{{ $t('book-course') }}</h4>
			</template>
			<div class="warp">
				<el-table ref="multipleTableRef"
					:data="list" 
					stripe 
					style="width: 100%" v-loading="loading">
					<el-table-column prop="name" label="#" width="50">
						<template #default="scope">
							<div class="tu-nickname">{{ scope.$index + 1 }}</div>
						</template>
					</el-table-column>
					<el-table-column prop="name" :label="$t('Name & Subject')">
						<template #default="scope">
							<div class="tu-nickname">{{ scope.row.user_info.nickname }}</div>
							<div class="tu-subject">{{ scope.row.subject.subject_name }}({{ scope.row.subject.level_name }})</div>
						</template>
					</el-table-column>
					<el-table-column prop="date" :label="$t('Date & Time')">
						<template #default="scope">
							<div class="tu-date">{{ handleDateShow(scope.row) }}</div>
							<div class="tu-time">{{ handleTimeShow(scope.row) }}</div>
						</template>
					</el-table-column>
					<el-table-column prop="other" :label="$t('Price & Status')">
					<template #default="scope">
							<div class="tu-price">{{ scope.row.currency }}{{ scope.row.price }}</div>
							<div class="tu-status">{{ scope.row.status_name }}</div>
						</template>
				</el-table-column>
				</el-table>
			</div>
			<div class="btn">
				<el-button type="primary" v-if="operation.is_pay" @click="payBooking">{{ $t('PAY') }}</el-button>
				<el-button type="primary" v-if="operation.is_pass" @click="passBooking">{{ $t('AGREE') }}</el-button>
				<el-button type="danger" v-if="operation.is_refuse" @click="refuseBooking">{{ $t('REFUSE') }}</el-button>
				<el-button @click="handleClose()">{{ $t('CLOSE') }}</el-button>
			</div>
		</el-drawer>
	</div>
</template>

<script>
import { ElMessageBox } from 'element-plus'
import TimeControl from '@/utils/time-control'
import CourseApi from '@/api/course'

export default {
	name: 'CourseDetails',
	components: {

	},
	data() {
		return {
			loading: true,
			dialogVisible: false,
			direction: 'rtl',
			info: [],
			list: [],
			operation: {
				is_pass: false,
				is_refuse: false,
				is_pay: false
			},
			cacheDatas: {}
		}
	},
	created() {
		
	},
	computed: {
		
	},
	methods: {
		// 打开预约课程弹框
		open(row) {
			this.loading = true;
			this.info = [];
			this.list = [];
			this.operation = {
				is_pass: false,
				is_refuse: false,
				is_pay: false
			};
			if(!!row) {
				this.info = row;
				this.getApiDetailData()
			} else {
				this.loading = false;
			}
			this.dialogVisible = true;
		},
		close() {
			this.$emit('change');
			this.dialogVisible = false;
		},
		handleClose() {
			this.close();
		},
		getApiDetailData() {
			if(!this.info.course_ids) {
				return ;
			}
			CourseApi.index({course_ids: this.info.course_ids}).then(res => {
				this.loading = false;
				this.list = res.data
				for(let i in res.data) {
					if(!this.operation.is_pass && res.data[i].operation) {
						if(res.data[i].operation.is_pass) {
							this.operation.is_pass = true;
						}
					}
					if(!this.operation.is_refuse && res.data[i].operation) {
						if(res.data[i].operation.is_refuse) {
							this.operation.is_refuse = true;
						}
					}
					if(!this.operation.is_pay && res.data[i].operation) {
						if(res.data[i].operation.is_pay) {
							this.operation.is_pay = true;
						}
					}
				}
			})
		},		
		handleDateShow(row) {
			let book_time = row.book_time * 1000;
			return TimeControl.getFormatTime(book_time)
		},
		handleTimeShow(row) {
			let book_time = row.book_time * 1000;
			let book_end_time = (row.book_time + row.second) * 1000;
			return TimeControl.getFormatTime(book_time, "HH:mm") + ' ~ ' + TimeControl.getFormatTime(book_end_time, "HH:mm")
		},
		payBooking() {
			if(!this.info.course_ids) {
				this.$message.success(this.$t('course does not exist'));
				return ;
			}
		},
		passBooking() {
			if(!this.info.course_ids) {
				this.$message.success(this.$t('course does not exist'));
				return ;
			}
			ElMessageBox.confirm(this.$t('whether to agree to book a course'), '', {
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
				type: 'warning'
			}).then(() => {
				let from = {
					stat: 1,
					ids: this.info.course_ids,
					reson: ''
				}
				CourseApi.verify(from).then(res => {
					this.$message.success(this.$t('course reservation is successful'));
					this.handleClose();
				})
			})
		},
		refuseBooking() {
			if(!this.info.course_ids) {
				this.$message.success(this.$t('course does not exist'));
				return ;
			}
			ElMessageBox.confirm(this.$t('whether to decline the course'), '', {
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
				type: 'warning'
			}).then(() => {
				let from = {
					stat: 2,
					ids: this.info.course_ids,
					reson: ''
				}
				CourseApi.verify(from).then(res => {
					this.$message.success(this.$t('scheduled class declined'));
					this.handleClose();
				})
			})
		}
	}
}
</script>
<style lang="less">
#tu-course-details {
	.btn {
		margin: 15px 0;
	}

	.warp {
		.tu-nickname {
			color: var(--el-color-primary);
			font-size: 16px;
			font-weight: 600;
			padding: 6px 0;
		}

		.tu-subject, .tu-time, .tu-status {
			color: var(--el-color-info);
		}

		.tu-date {
			color: var(--el-color-primary);
			font-size: 16px;
			font-weight: 400;
			padding: 6px 0;
		}

		.tu-price {
			color: var(--el-color-primary);
			font-size: 16px;
			font-weight: 400;
			padding: 6px 0;
		}
	}
}
</style>