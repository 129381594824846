<template>
	<div id="tu-chat-content">
		<el-row :gutter="20">
			<el-col :span="4">
		    <SecondMenu />
			</el-col>
			<el-col :span="20">
				<div class="warp" v-if="chat_info">
					<div class="desc-style">
						<div>{{ chat_info.user_info.nickname }}</div>
						<div><el-button size="mini" @click="$router.go(-1)">{{ $t('go-back') }}</el-button></div>
					</div>
					<!-- <el-descriptions>
					    <el-descriptions-item label="Name">{{ chat_info.user_info.nickname }}</el-descriptions-item>
					    <el-descriptions-item label="Class Name">{{ chat_info.user_info.class_name }}</el-descriptions-item>
					    <el-descriptions-item label="Birthday">{{ chat_info.user_info.birthday }}</el-descriptions-item>
					    <el-descriptions-item label="Subject">{{ chat_info.user_info.subject_id }}</el-descriptions-item>
					    <el-descriptions-item label="Problem to be solved">{{ chat_info.user_info.content }}</el-descriptions-item>
					 </el-descriptions> -->
				</div>
				<div class="chat-content">
					<div class="title">
						<div style="padding: 5px 0px;">{{ $t('chat-content') }}</div>
						<div><!-- <el-button type="danger" size="small">关于课程</el-button> --></div>
					</div>
					<div class="content-main">
						<ul id="content-main-dom" ref="mcontent" 
							v-infinite-scroll="loadContent" 
							infinite-scroll-distance="30" 
							infinite-scroll-disabled="disabled"
							class="infinite-list" style="overflow: auto">
					    <li class="infinite-list-item" v-for="item, index in list" :key="index">
					    	<div v-if="item.lt=='center'" class="center">
					    		<div class="content">
										<div class="nickname">
											<span class="time">{{ handleLoadTime(item.last_time) }}</span>
										</div>
										<a class="detail" @click="$refs.CourseDetails.open(item);" v-html="handleContent(item.content)"></a>
									</div>
					    	</div>
								<div v-else :class="item.lt">
									<div :style="{ position: item.lt == 'left' ? '' : 'absolute', marginTop: '5px' }">
										<el-avatar v-if="item.user_info.avatar_url" :size="50" :src="item.user_info.avatar_url"></el-avatar>
										<el-avatar v-else :size="50">{{ item.user_info.slot }}</el-avatar>
									</div>
									<div class="content">
										<div class="nickname">
											<span class="name">{{ item.user_info.nickname }}</span>
											<span class="time">{{ handleLoadTime(item.last_time) }}</span>
										</div>
										<div class="detail" v-html="handleContent(item.content)"></div>
										<div class="tran" v-if="item.lt == 'left' && item.tran_content">
											<div><el-tooltip placement="top" content="Translate content"><i class="fa fa-globe"></i></el-tooltip></div>
											<div style="word-break: break-all;" v-html="handleContent(item.tran_content)"></div>
										</div>
										<div style="margin: 5px 0;" v-if="item.lt == 'left' && !item.tran_content">
											<el-check-tag checked @click="onTranslateContent(index)" v-if="translate.indexOf(index) == -1">Translate</el-check-tag>
											<el-check-tag checked v-else>In translation</el-check-tag>
										</div>
									</div>
								</div>
					    </li>
					    <!-- <li v-if="loading == 1" class="infinite-list-item">Loading...</li>
					    <li v-if="loading == 2" class="infinite-list-item">Nothing</li> -->
					 	</ul>
					</div>
					<div class="content-text">
						<el-input
					    v-model="content"
					    :rows="6"
					    type="textarea"
					    placeholder="Please input"
					  />
					</div>
					<div class="content-btn">
						<el-row>
						    <el-button v-if="userType==1" type="danger" 
						    	@click="$router.push({ path: `/teacher/${chat_info.tutor_id}` })">{{ $t('teacher-details') }}</el-button>
						    <el-button v-if="userType==2" type="danger" @click="handleBookCourseOpen">{{ $t('book-course') }}</el-button>
						    <el-button type="primary" @click="sendMessage">{{ $t('send-message') }}</el-button>
						  </el-row>
					</div>
				</div>
			</el-col>
		</el-row>
		<CourseDetails ref="CourseDetails"/>
		<BookCourse ref="bookCourse" @change="handleBookClose" />
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import SecondMenu from '@/components/SecondMenu'
import ChatApi from '@/api/chat'
import TimeControl from '@/utils/time-control'
import CourseDetails from '@/components/CourseDetails'
import BookCourse from '@/components/BookCourse'

export default {
	name: "chat-content",
	components: {
		SecondMenu,
		CourseDetails,
		BookCourse
	},
	data() {
		return {
			id: 0,
			content: "",
			list: [],
			loading: 0,
			old_scroll_height: 0,
			param: {
				opt: 'new',
				message_id: 0,
				min_id: 0,
				max_id: 0,
				prev_status: 0
			},
			lock: false,
			timer: null,
			chat_info: null,
			translate: []
		}
	},
	created() {
		this.id = this.$route.params.id
		this.getChatInfo()
		this.loadContent()
	},
	computed: {
		...mapGetters([
			'userType'
		])
	},
	methods: {
		handleBookCourseOpen() {
			if(!!this.chat_info) {
				let uid = this.chat_info.study_id || 0;
				let study_list = this.chat_info.user_info.profile || {};
				this.$refs.bookCourse.open({uid: uid, study_list: study_list});
			}
		},
		handleBookClose() {

		},
		getChatInfo() {
			ChatApi.info(this.id).then(res => {
				this.chat_info = res.data;
			})
		},
		sendMessage() {
			if(this.content.length <= 0) {
				this.$message.error(this.$t('pleash-enter-content'));
				return ;
			}
			ChatApi.put(this.id, {content: this.content}).then(res => {
				this.content = '';
				this.loadContent();
			});
		},
		loadContent(opt) {
			if(this.lock) {
				return ;
			}
			this.lock = true;
			this.loading = 2
			this.param.opt = !!opt && opt == 'old' ? 'old' : 'new';
			this.param.message_id = !!opt && opt == 'old' ? this.param.min_id : this.param.max_id;
			ChatApi.show(this.id, this.param).then(res => {
				this.lock = false;
				if(res.data.length <= 0) {
					return ;
				}
				let data = res.data.reverse();
				if(this.list.length <= 0) {
					this.list = data;
				} else {
					if(this.param.opt == 'new') {
						this.list = this.list.concat(data)
					} else {
						this.list = data.concat(this.list)
					}
				}

				if(this.list.length > 0) {
					this.param.min_id = this.list[0].id
					this.param.max_id = this.list[this.list.length - 1].id
				}

				if(this.param.opt == 'new') {
					this.$nextTick(() => {
			          let msg = this.$refs.mcontent
			          this.$refs.mcontent.scrollTo({
			            top: msg.scrollHeight,
			            behavior: "smooth"
			          });
			        })
				}				
			})
		},
		handleContent(value) {
			let content_arr = []
			value.split('\n').forEach(item=>content_arr.push(`<div>${item.trim()}</div>`));
			return content_arr.join('');
		},
		handleLoadTime(last_time) {
			return TimeControl.getFormatTime(last_time);
		},
		mainContentPush() {

		},
		chatScrollChange: function() {
		  // 滚动条位置
		  let scrollTop = document.getElementById('content-main-dom').scrollTop;
		  // 当前dom的高度
		  let clientHeight = document.getElementById('content-main-dom').clientHeight;
		  // 滚动条高度
		  let scrollHeight = document.getElementById('content-main-dom').scrollHeight;

		  // 记录当前dom高度
		  this.old_scroll_height = scrollHeight;
		  // console.log(scrollTop, clientHeight, scrollHeight)
		  // return ;
		  // 若 scrollTop == 0 时 加载旧数据
		  if(scrollTop <= 0 && this.param.prev_status == 0) {
		      this.loadContent('old');
		      return ;
		  }
		  // 若 scrollTop + clientHeight + 50 == scrollHeight 加载新数据
		  // let current_height = scrollTop + clientHeight + 50;
		  // if(current_height >= scrollHeight && this.chat_list.next_status == 0) {
		  //     // this.chatList(0, 1);
		  //     return ;
		  // }
		},
		onTranslateContent(index) {
			if(this.translate.indexOf(index) != -1) {
				return ;
			}
			let info = this.list[index];
			this.translate.push(index)
			ChatApi.trans(info.id).then(res => {
				info.setInt = setInterval(function(){
					ChatApi.info(info.id).then(res => {
						if(!!res.data.tran_content) {
							info.tran_content = res.data.tran_content;
							clearInterval(info.setInt);
						}
					})
				}, 1500);
			});
		}
	},
	mounted() {
		document.getElementById('content-main-dom').addEventListener('scroll', this.chatScrollChange, true)
		this.timer = setInterval(this.loadContent, 5000)
	},
	beforeUnmount() {
		clearInterval(this.timer)
	}
}
</script>
<style lang="less">
#tu-chat-content {
	.warp {
		border: 1px solid var(--el-border-color-light);
	  padding: 15px 10px;
	  margin-bottom: -1px;

	  .desc-style {
			display: flex;
			justify-content: space-between;
			padding: 12px 0;

	  	div:first-child {
	  		color: var(--el-color-primary);
	  		font-size: 21px;
	  	}
	  }

	  .btn {
	  	justify-content: flex-end;
	  }
	}
	.chat-content {
		border: 1px solid var(--el-border-color-light);

		.title {
			display: flex;
	    justify-content: space-between;
	    padding: 12px 15px;
	    background: var(--el-color-primary);
	    color: var(--el-color-white);
	    align-items: center;
	    flex-direction: row;
	    font-weight: var(--el-font-weight-primary);
		}

		.content-main {
			border-bottom: 1px solid var(--el-border-color-light);

			.infinite-list {
			  height: 500px;
			  padding: 0;
			  margin: 0;
			  list-style: none;

			  .infinite-list-item {
			  	position: relative;
			  	padding: 15px;

			  	.center {
			  		text-align: center;

			  		.content {
			  			.nickname {
								font-size: 13px;

								.time {
									margin-left: 8px;
									color: var(--el-color-info);
								}
							}

							.detail {
								background: var(--el-color-info-lighter);
						    padding: 10px 12px;
						    line-height: 1.6;
						    font-size: 14px;
						    word-break: break-word;
						    border-radius: 5px;
						    margin: 5px 0 0 0;
						    display: inline-block;
						    cursor: pointer;
						    text-decoration: underline;
							}
			  		}
			  	}

			  	.left {
						display: flex;
						align-items: flex-start;
						flex-direction: row;

						.content {
							margin-left: 15px;
							max-width: 80%;

							.nickname {
								font-size: 13px;
								text-align: left;

								.name {
									font-weight: 600;
								}

								.time {
									margin-left: 8px;
									color: var(--el-color-info);
								}
							}

							.detail {
								background: var(--el-color-info-lighter);
						    padding: 10px 12px;
						    line-height: 1.6;
						    font-size: 14px;
						    word-break: break-word;
						    border-radius: 5px;
						    margin: 5px 0 0 0;
						    position: relative;
							}

							.detail:before {
								position: absolute;
								width: 0;
								height: 0;
								left: -10px;
								top: 8px;
								content: '';
								border-right: solid 10px var(--el-color-info-lighter);
								border-top: solid 10px transparent;
								border-bottom: solid 10px transparent;
							}

							.tran {
								background: var(--el-color-warning-light);
								border: 1px solid var(--el-color-warning-lighter);
								border-radius: 4px;
								padding: 10px 12px;
								margin: 10px 0 0 0;
								display: flex;
								align-items: flex-start;
								flex-direction: row;
								font-size: 14px;

								& > div:nth-child(2) {
									margin-left: 5px;
								}
							}
						}
			  	}

			  	.right {
			  		display: flex;
						align-items: flex-start;
						flex-direction: row;
						justify-content: flex-end;

						.content {
							margin-right: 65px;
							max-width: 80%;

							.nickname {
								font-size: 13px;
								text-align: right;

								.name {
									font-weight: 600;
								}

								.time {
									margin-left: 8px;
									color: var(--el-color-info);
								}
							}

							.detail {
								background: var(--el-color-primary);
						    padding: 10px 12px;
						    line-height: 1.6;
						    font-size: 14px;
						    word-break: break-word;
						    border-radius: 5px;
						    margin: 5px 0 0 0;
						    position: relative;
						    color: var(--el-color-white);
							}

							.detail:after {
								position: absolute;
								width: 0;
								height: 0;
								right: -10px;
								top: 10px;
								content: '';
								border-left: solid 10px var(--el-color-primary);
								border-top: solid 10px transparent;
								border-bottom: solid 10px transparent;
							}

							.tran {
								background: var(--el-color-warning-light);
								border: 1px solid var(--el-color-warning-lighter);
								border-radius: 4px;
								padding: 10px 12px;
								margin: 10px 0 0 0;
								display: flex;
								align-items: flex-start;
								flex-direction: row;
								font-size: 14px;

								& > div:nth-child(2) {
									margin-left: 5px;
								}
							}
						}
			  	}
			  }
			}
		}


		.content-text textarea {
			border: 0;
		}

		.content-btn {
			border-top: 1px solid var(--el-border-color-light);
			padding: 15px;
			.el-row {
				justify-content: flex-end;
			}
		}
	}
}
</style>